.toolbar-preview-section {
  display: flex;
  align-items: center;

  &__naming-convention {
    margin-right: 16px;
  }
}

.naming-convention {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #10384f;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */

  > img {
    margin-right: 5px;
  }
}

.disabled-table-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(0.1px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 1.5em;
  font-family: Arial, sans-serif;
  z-index: 10;
  pointer-events: all;
}
