.distributor-name {
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #10384f;
}

.distributor-id {
  font-family: 'Helvetica Neue';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #516e7f;
}

.report-name {
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #10384f;
}

.list-item {
  height: 25px;
}

a {
  text-decoration: none;
  color: inherit;
}
