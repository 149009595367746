.inform-cards {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__inform-card {
    width: 31%;
  }
}

.panel {
  display: flex;
  justify-content: space-between;
  width: auto;
  height: 360px;
  margin-top: 30px;

  &__chart {
    width: 31%;
  }

  &__table {
    width: 65.5%;
    height: auto;
  }

  .table__body {
    height: 320px;
    overflow-y: scroll;
  }
}

.listChart {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue';
  font-size: 11px;
  color: #10384f;
  li {
    margin-top: 16px;
    display: flex;
    div {
      flex: 1;
      border-bottom: 1px dashed black;
    }
  }
}

.flexible-charts-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  margin-top: 50px;
}

.submission-attempts-container {
  flex: 35%;
}

.rejection-reasons-charts {
  flex: 55%;
  display: flex;
  gap: 30px;
}

.list-chart-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  min-width: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.rejection-pie-container {
  flex: 1;
}
