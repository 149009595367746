.button-panel {
  display: flex;
  justify-content: end;

  &__buttons {
    margin-bottom: 13px;
    width: 181px;
    display: flex;
    justify-content: space-between;
  }
}

.notification-rules {
  min-height: calc(45% - 50px);
  background-color: rgb(255, 255, 255);
  padding: 0 24px 24px 24px;
  margin-top: 2px;
  border-top: 1px solid rgba(0, 0, 0, 0.14);
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);

  &__title {
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #10384f;
    margin-top: 0;
    padding-top: 24px;
    margin-bottom: 24px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    >* {
    margin-right: 15px;
    margin-bottom: 15px;
    }
  }
}

.after-due-date {
  max-width: 352px;
  min-width: 352px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin: 0;
    width: 95%;
    text-align: start;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;
    background-color: rgba(235, 237, 245, 1);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 4px;
    color: #10384f;
  }

  &__selector {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  &__day-of-week-selector {
    margin-top: 25px;
    height: 36px;
  }
}

.before-due-date {
  max-width: 352px;
  min-width: 352px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin: 0;
    width: 95%;
    text-align: start;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;
    background-color: rgba(235, 248, 255, 1);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 4px;
    color: #10384f;
  }

  &__checkboxes {
    margin-top: 11px;
    margin-bottom: 11px;
  }
}

.before-start-date {
  max-width: 352px;
  min-width: 352px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin: 0;
    width: 95%;
    text-align: start;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;
    background-color: rgba(199, 235, 255, 1);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 4px;
    color: #10384f;
  }

  &__checkboxes {
    margin-top: 11px;
    margin-bottom: 11px;
  }
}

.selector-container {
  width: 240px;
}

.number-input {
  border: none;
  width: 36px;
  outline: none;
  padding: 4px;
  border-bottom: 2px solid rgba(108, 111, 128, 1);
  font-size: 16px;
  margin-left: -18px;
  text-align: left;
  padding-left: -36px;
  margin-right: 5px;
}

.notification-periods {
  min-height: calc(45% - 50px);
  background-color: var(--page-content-background);
  padding: 24px;

  &__title {
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #10384f;
    margin-bottom: 24px;
  }

  &__periods {
    width: 240px;
  }
}

.notification-periods-content {
  margin-top: 24px;
}

.selector-container {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__desciption {
    padding-left: 25px;
    padding-top: 3px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.monthly-periods,
.weekly-container {
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.custom-periods {
  display: flex;
  flex-direction: column;

  .custom-period:not(:last-child) {
    margin-bottom: 15px;
  }
}

.custom-period {
  width: 100%;
  display: flex;
  align-items: baseline;

  >* {
    margin-right: 25px;
  }
}