.chart-container {
  position: relative;
  margin-top: 16px;
}

.chart-container::after {
  content: 'Inventory';
  position: absolute;
  font-family: 'Helvetica Neue';
  font-size: 12px;
  top: 15px;
  left: 145px;
  padding: 2px 4px;
  border: 1px solid #ebedf5;
  border-radius: 2px;
  z-index: 10;
  background-color: #fafafc;
  color: #acafbf;
}

.chart-container::before {
  content: 'Sell-out';
  position: absolute;
  font-family: 'Helvetica Neue';
  font-size: 12px;
  top: 15px;
  right: 5%;
  padding: 2px 4px;
  border: 1px solid #ebedf5;
  border-radius: 2px;
  z-index: 10;
  background-color: #fafafc;
  color: #acafbf;
}
