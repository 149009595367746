.card {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  --card-status-color: #dfa100;

  &.--danger-status {
    --card-status-color: #de0043;
  }

  width: auto;
  min-height: 191px;
  padding: 20px;
  border-radius: 10px;
  background: white;

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #10384f;
  }

  &__update {
    font-size: 14px;
    font-style: normal;
    color: #109000;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  &__body {
    display: flex;
    flex-direction: column;
    text-align: start;
  }

  &__info {
    margin-top: 18px;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px; /* 112.5% */
    letter-spacing: 0.25px;
  }
  &__explaining {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 100% */
    letter-spacing: 0.25px;
    color: #516e7f;
  }

  &__status {
    margin-top: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--card-status-color);
  }

  &__links {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    > * {
      text-decoration: none;
      color: rgba(0, 145, 223, 1);
    }
  }
}
