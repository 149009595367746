.sidebar {
  display: flex;
  flex-direction: column;
  text-align: justify;
  min-width: 225px;
  font-size: 16px;
  font-weight: 400;
  background-color: white;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: rgba(11, 11, 11, 0.1);


  &__selector {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  &__navigation {
    margin-bottom: 25px;
  }
                               
  &__onboarding {
    margin-top: 21px;
    border-top: 1px solid #FFFFFF;
  }
}

.navigation {
  width: 100%;
}
.board-name {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  > .icon {
    padding-right: 32px;
  }
  > .nav-link {
    text-decoration: none; /* Remove underline */
    color: var(--text-primary);
    padding-block: 4px;
  }
}
.logo-section {
  display: flex;
  margin: 16px 16px 24px;

  &__country {
    color: rgba(16, 56, 79, 0.73);
  }

  &__app-name {
    //styleName: Body 14;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    color: rgba(16, 56, 79, 1);
  }

  &__app-type {
    //styleName: Body 14;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    color: rgba(16, 56, 79, 0.73);
  }
}


.logo-section__text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
  font-size: 14px;
}

.--active-board {
  background-color: rgba(235, 250, 255, 1);
}

.--inactive-board {
  background-color: none;
}
