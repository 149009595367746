.buttons-section{
    width: 17%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.alert-2 {
    width: 100%;
    margin-bottom: 16px;
}