.button-panel {
  display: flex;
  justify-content: end;

  &__buttons {
    margin-bottom: 13px;
    width: 181px;
    display: flex;
    justify-content: space-between;
  }
}

.onboarding-timelines-notification-page {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  > .notification-rules {
    flex: 1;
  }
}
