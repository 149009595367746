.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.contacts-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
