:root {
  //debt: shoud be moved to separete file in future
    --page-background: rgba(249, 252, 255, 1);
    --page-content-background: rgb(255, 255, 255);

    --green-5: #F5FDEC;
    --green-10: #E1F9C7;
    --bright-green: #89D329;
    --green: #66B512;

    --blue-5: #EBF8FF;
    --blue-10: #C7EBFF;
    --bright-blue: #00BCFF;
    --blue: #0091DF;
    --light-blue: #FAFCFF;

    --red: #E05781;
    --orange: #FF8A65;  

    --grey-50: #FAFAFC;
    --grey-200: #EBEDF5;
    --grey-300: #E1E3EB;
    --grey-500: #ACAFBF;
    --grey-600: #6C6F80;
    --grey-700: #474A59;
    --grey-800: #303240;
    
    --raspberry-10: #FCCFDD;
  }