.progress-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;


  .progress {
    position: relative;
    width: 100%;
    height: 100%;

  }
}

canvas {
  position: absolute;
  top: -100px;
  left: -100px;
}

.progress-label {
  position: absolute;
  transform: translate(-50%, -50%);
  font-family: Arial, sans-serif;
  font-size: 38px;
  color: #333;
  width: 150px;
}