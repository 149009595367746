.chart {
  box-sizing: border-box;
  padding: 20px;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  border-radius: 10px;
  background: #fff;

  &__name {
    width: 100%;
    text-align: start;
    color: #10384f;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__legend {
    width: 100%;
  }
}

.legend {
  width: 200px;
  > ul {
    list-style: none;
    padding: 0;
    > li {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
  }

  &__color {
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    border-radius: 100%;
  }

  &__text {
    color: #10384f;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
