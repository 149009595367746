.error-wrapper {
    display: flex;
    justify-content: center;
    margin-left: -15px;
    margin-top: 20vh;
    min-height: 100vh;
  }
  
  .error-content {
    max-width: 600px;
    &__header {
      font-weight: 500;
      font-size: 75px;
      line-height: 92px;
      text-align: left;
      margin: 0;
      color: rgba(81, 110, 127, 1);
    }
  
    &__text {
      margin-top: 8px;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: left;
      color: rgba(81, 110, 127, 1);
    }
  
    &__button {
      width: 96px;
      height: 42px;
      margin-top: 32px;
      border-radius: 1px;
      border: 1px dashed rgba(225, 227, 235, 1);
      padding: 8px 16px;
      margin-bottom: 10px;
      color: rgba(16, 56, 79, 1);
      font-weight: 500;
      background-color: rgba(250, 250, 252, 1);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
  
      &:hover {
          background-color: rgb(239, 239, 239); 
      }
    }
  
    &__button-text {
      color: rgba(16, 56, 79, 1);
      font-weight: 500;
    }
  }
  