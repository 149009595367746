.notification-page {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .notification-switcher {
    display: flex;
    padding: 9px 16px 0px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);

    > * {
      margin-right: 16px;
      padding-bottom: 4px;
      cursor: default;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px; // Adjusted to match the value closely
      text-align: left;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.6);
    }

    .selected {
      color: rgba(33, 150, 243, 1);
      border-bottom: 2px solid rgba(33, 150, 243, 1);
    }
  }

  .notification-control {
    display: flex;
    justify-content: space-between;
    padding: 9px 16px 9px 16px;

    &__buttons {
      > * {
        margin-left: 8px;
      }
    }
  }
}
