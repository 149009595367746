.distributor-cell {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  &__name {
  }

  &__id {
  }
}

.actions-hided {
  display: none;
}
