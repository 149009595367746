.default-layout {
  display: grid;
  width: auto;
  grid-template-columns: 1fr 6fr; // 210px for the first column, equal fractions for the remaining columns
  grid-template-rows: auto 1fr;
  min-height: 100vh;
}

.sidebar {
  grid-row: 1/3;
}

.header {
  grid-column: 2/3;
}

body {
  background-color: var(--page-background);
}

main {
  width: auto;
  position: relative;
  padding: 32px 0px;
  width: calc(100vw - 74px - 225px);
  margin: auto;
}

.page-content {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
