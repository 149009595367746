.header {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  padding-left: 28px;
  height: 68px;
  font-weight: 400;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: rgba(11, 11, 11, 0.1);

  > h1 {
    font-size: 24px;
    font-weight: 400;
    color: rgba(16, 56, 79, 1);
  }
}

.user-profile-tab {
  min-width: 160px;
  max-width: 320px;
  margin-right: 32px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__name {
    margin-left: 15px;
  }

  &__avatar {
    width: 24px;
    height: 24px;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #10384f;
  }
}

.header-popup {
  position: relative;
  position: absolute;
  top: 45px;
  right: 10px;
  width: 170px;
  height: 60px;
}

.text {
  line-height: 45px;
}

.page-name {
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.user-profile-dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  &__name {
    margin-right: 12px;
  }

  &__ava {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: rgba(102, 181, 18, 1);
    color: white;
  }
}
