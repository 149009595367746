.day-selector {
    width: 265px;
    display: flex;
    justify-content: space-between;
  
    &__day {
      width: 36px;
      height: 36px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      background-color: rgba(235, 237, 245, 1);
      color: rgba(16, 56, 79, 1);
    }
  
    .--selected-day {
      background-color: rgba(33, 150, 243, 1);
      color: rgba(255, 255, 255, 1);
    }
  
    .--disabled-day {
      background-color: rgba(235, 237, 245, 1);
      color: rgba(16, 56, 79, 1);
    }
  }