.main-table {
  min-height: 350px;

  &__head-cell {
    color: #10384f;
    font-size: 14px;
    //font-family: Helvetica Neue;
    font-weight: 500;
    line-height: 18px;
    word-wrap: break-word;
  }

  &__table-header {
    background-color: #e7f8ff;
    text-transform: uppercase;
  }
  &__checkbox {
    width: 10px;
    height: 8px;
  }
  &__pagination {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &__column-head-cell {
    > img {
      width: 12px;
      height: 12px;
      line-height: none;
      margin-top: 6px;
      margin-left: 5px;
    }
  }
}
