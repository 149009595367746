.templates-table-wrapper {
}

.template-control-panel {
  background-color: white;
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.action-cell {
  position: relative;
}

.popup {
  position: absolute;
  top: 0;
}

.dialog-header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 16px;

  h2 {
    font-size: 20px;
    font-family: "Helvetica Neue";
    color: rgba(16, 56, 79, 1);
    font-weight: 500;
    line-height: 30px;
  }
}

.dialog-content {
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 16px;
  }

  .editor-wrapper.has-error {
    border: 1px solid #d32f2f;
  }

  .editor-wrapper {
    max-width: 100%;
    max-height: 525px;
    min-height: 225px;
    overflow-y: scroll;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 3px;
  }
  .rte-error {
    color: #d32f2f;
    font-family: "Helvetica Neue";
    font-size: 12px;
    margin-left: 14px;
    letter-spacing: 0.39996px;
    line-height: 19.92px;
    margin-top: -13px;
  }
}

.dialog-footer {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 16px;

  &__input {
    width: 100%;
  }

  h2 {
    font-size: 20px;
    font-family: "Helvetica Neue";
    color: rgba(16, 56, 79, 1);
    font-weight: 500;
  }

  & .--email-template-editor {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.--email-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .footer-buttons {
    margin-right: 24px;
    > * {
      margin-left: 8px;
    }
  }
}
